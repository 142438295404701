<div class="navbar-section">
  <div class="navbar-container">
    <a href="https://carleton.ca/scs/">
      <div class="navbar-logo">
        <!-- svelte-ignore a11y-missing-attribute -->
        <img src="/carleton_logo.png" />
        <div class="navbar-logo-text">School of Computer Science</div>
      </div>
    </a>
  </div>
</div>

<style type="text/scss">.navbar-section {
  position: fixed;
  top: 0;
  background-color: white;
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  z-index: 110;
}
.navbar-section a:hover {
  text-decoration: none !important;
}
.navbar-section .navbar-container {
  width: 100%;
  max-width: 1024px;
  margin: 0.5rem;
  display: flex;
}
.navbar-section .navbar-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar-section .navbar-logo img {
  width: 3rem;
}
.navbar-section .navbar-logo-text {
  margin-left: 1rem;
  font-weight: bold;
  font-size: 1.1rem;
  color: #353434;
  font-family: Arial, Helvetica, sans-serif;
}
@media screen and (max-width: 855px) {
  .navbar-section .navbar-logo {
    margin-left: 1.4rem;
  }
}
@media screen and (max-width: 550px) {
  .navbar-section .navbar-logo {
    margin-left: 0.3rem;
  }
}</style>
