<div class="footer">
  <a href="https://carleton.ca/scs/">
    © 2021 Carleton School of Computer Science
  </a>
</div>

<style type="text/scss">.footer {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  height: 10rem;
  font-size: 16px;
  text-align: center;
}
.footer a {
  color: inherit;
  text-decoration: none;
}</style>
