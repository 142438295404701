<script>
  import {Question} from '../../components';
  export let ui;
  export let object;
</script>

<div class="assignent">
  {#each ui.questions as question}
    <div class="assignment-question">
      <Question {question} assignment={object} />
    </div>
  {/each}
</div>

<style type="text/scss">.assignent {
  margin-bottom: 2rem;
}

.assignment-question {
  margin-top: 2rem;
  margin-bottom: 3rem;
}

hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
}</style>
