<script>
  // props
  export let answer;
  export let assignment;
  export let questionIndex;
</script>

<div
  class="answer {answer.state}"
  on:click={() =>
    assignment.setSelectedAnswer(questionIndex, answer.assignmentIndex)}
>
  <div class="answer-inner">
    <div class="answer-letter">{answer.letter})</div>
    <div class="answer-body">{answer.body}</div>
  </div>
</div>

<style type="text/scss">.answer {
  border: 1px solid #ccc !important;
  border-radius: 0.5rem;
  height: 3rem;
  margin: 1rem;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.answer-inner {
  padding: 0 1rem;
  display: flex;
  justify-content: left;
  align-items: center;
  height: 2rem;
  width: 100%;
}

.selected {
  background-color: #c5edff;
}

.unselected {
  background-color: white;
}

.unselected:hover {
  background-color: #e2f6ff;
}

.correct {
  background-color: #80ff80;
}

.incorrect {
  background-color: #ff4242;
}

.solution {
  background-color: #c1f0c1;
}

.solution:hover {
  background-color: #a1fda1;
}

.answer-letter {
  margin-right: 1rem;
}</style>
