<script>
  import {createEventDispatcher} from 'svelte';

  // props
  export let label;

  // onClick
  const dispatch = createEventDispatcher();

  const onClick = event => {
    dispatch('click', event);
  };
</script>

<button on:click={onClick}>
  <div>{label}</div>
</button>

<style type="text/scss">button {
  color: white;
  border-width: 0em;
  border-radius: 0.2rem;
  background-color: #fa3030;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 1.05rem;
  padding: 0.8rem 2.25rem;
  min-width: 8rem;
  max-height: 3rem;
  transition: all 0.15s;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

button:hover {
  background-color: #7a2f2f;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
}</style>
