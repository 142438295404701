<script>
  import {Answer} from '../../components';
  export let question;
  export let assignment;
</script>

<div class="question">
  <div class="question-container">
    <div class="question-number">{question.id}.</div>
    <div class="question-body">{question.body}</div>
  </div>
  {#each question.answers as answer}
    <Answer {answer} {assignment} questionIndex={question.assignmentIndex} />
  {/each}
</div>

<style type="text/scss">.question-container {
  display: flex;
}

.question-body {
  margin-bottom: 1rem;
}

.question-number {
  font-weight: bold;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}</style>
